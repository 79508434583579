import "../../Styles/employees.scss";

import {
  faArrowLeftLong,
  faSearch,
  faTimes,
  faTrash,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import WaveLoading from "../Util/WaveLoading";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ArrowBack, Close, Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Paper,
  Typography,
  Stack,
  IconButton,
  Divider,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Button,
  Select as MuiSelect,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { Add, Visibility } from "@mui/icons-material";
const swalConfig = {
  customClass: {
    container: "swal-container-class",
  },
};
export default function Employees() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newEmployeeDialog, setNewEmployeeDialog] = useState(false);
  const [viewEmployeeDialog, setViewEmployeeDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editEmployeeDialog, setEditEmployeeDialog] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [formData, setFormData] = useState({
    Name: "",
    Gender: "Male",
    Designation: "",
    Phone: "",
    Email: "",
    EmployeeNo: "",
    DOE: new Date().toISOString().split("T")[0],
    IDNo: "",
    NHIFNumber: "",
    NSSFNumber: "",
    MaritalStatus: "Single",
    College: "",
    Course: "",
    DOB: new Date().toISOString().split("T")[0],
    NextOfKinName: "",
    NextOfKinRelationship: "Other",
    NextOfKinContact: "",
    Gross: 0,
    Pension: 0,
    HELB: 0,
    Loan: 0,
    PAYE: true,
    NHIF: true,
    NSSF: true,
    HousingLevy: true,
    Status: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/employees");
      const fetchedData = await response.json();
      setData(
        fetchedData.map((employee) => ({
          ...employee,
          id: employee.ID,
        }))
      );
    } catch (error) {
      Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Error",
        text: "Failed to fetch employees",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/employees/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.success) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Employee created successfully",
          timer: 1500,
          showConfirmButton: false,
        });
        setNewEmployeeDialog(false);
        fetchEmployees();
      } else {
        throw new Error(data.error || "Failed to create employee");
      }
    } catch (error) {
      Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        const response = await fetch(`/api/employees/${id}`, {
          method: "DELETE",
        });
        const data = await response.json();

        if (data.success) {
          Swal.fire("Deleted!", "Employee has been deleted.", "success");
          fetchEmployees();
        } else {
          throw new Error(data.error || "Failed to delete employee");
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = async () => {
    try {
      Swal.fire({
        title: "Updating...",
        text: "Please wait while we update the employee details",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await fetch(`/api/employees/${editFormData.ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editFormData),
      });

      const data = await response.json();

      if (data.success) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Employee updated successfully",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          setEditEmployeeDialog(false);
          fetchEmployees();
        });
      } else {
        throw new Error(data.error || "Failed to update employee");
      }
    } catch (error) {
      Swal.fire({
        ...swalConfig,
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };

  const columns = [
    {
      field: "EmployeeNo",
      headerName: "Emp No",
    },
    {
      field: "Name",
      headerName: "Employee Name",
      flex: 1,
    },
    {
      field: "Designation",
      headerName: "Designation",
      width: 150,
    },
    {
      field: "Email",
      headerName: "Email",
    },
    {
      field: "Phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "Gross",
      headerName: "Gross Pay",
      type: "number",
      width: 130,
      renderCell: (params) =>
        params?.value?.toLocaleString("en-KE", {
          style: "currency",
          currency: "KES",
        }),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedEmployee(params.row);
              setViewEmployeeDialog(true);
            }}
          >
            <Visibility />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setEditFormData(params.row);
              setEditEmployeeDialog(true);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={() => handleDelete(params.row.ID)}>
            <Delete />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Employees</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setNewEmployeeDialog(true)}
        >
          New Employee
        </Button>
      </Stack>

      <Paper sx={{ p: 2 }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />
      </Paper>

      {/* New Employee Dialog */}
      <Dialog
        open={newEmployeeDialog}
        onClose={() => setNewEmployeeDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">New Employee</Typography>
            <IconButton
              onClick={() => setNewEmployeeDialog(false)}
              size="small"
            >
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Personal Information
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Full Name"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Gender</InputLabel>
                <MuiSelect
                  label="Gender"
                  name="Gender"
                  value={formData.Gender}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </MuiSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Email"
                name="Email"
                type="email"
                value={formData.Email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Phone"
                name="Phone"
                value={formData.Phone}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="ID Number"
                name="IDNo"
                value={formData.IDNo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Date of Birth"
                name="DOB"
                type="date"
                value={formData.DOB}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Marital Status</InputLabel>
                <MuiSelect
                  label="Marital Status"
                  name="MaritalStatus"
                  value={formData.MaritalStatus}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </MuiSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Education
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="College/University"
                name="College"
                value={formData.College}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Course"
                name="Course"
                value={formData.Course}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Next of Kin Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Next of Kin Name"
                name="NextOfKinName"
                value={formData.NextOfKinName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Relationship</InputLabel>
                <MuiSelect
                  label="Relationship"
                  name="NextOfKinRelationship"
                  value={formData.NextOfKinRelationship}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Spouse">Spouse</MenuItem>
                  <MenuItem value="Parent">Parent</MenuItem>
                  <MenuItem value="Child">Child</MenuItem>
                  <MenuItem value="Sibling">Sibling</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </MuiSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Next of Kin Contact"
                name="NextOfKinContact"
                value={formData.NextOfKinContact}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Employment Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Employee No"
                name="EmployeeNo"
                value={formData.EmployeeNo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Designation"
                name="Designation"
                value={formData.Designation}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="NHIF Number"
                name="NHIFNumber"
                value={formData.NHIFNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="NSSF Number"
                name="NSSFNumber"
                value={formData.NSSFNumber}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Date of Employment"
                name="DOE"
                type="date"
                value={formData.DOE}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Salary Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Gross Pay"
                name="Gross"
                type="number"
                value={formData.Gross}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.PAYE}
                      onChange={handleInputChange}
                      name="PAYE"
                    />
                  }
                  label="PAYE"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.NHIF}
                      onChange={handleInputChange}
                      name="NHIF"
                    />
                  }
                  label="NHIF"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.NSSF}
                      onChange={handleInputChange}
                      name="NSSF"
                    />
                  }
                  label="NSSF"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.HousingLevy}
                      onChange={handleInputChange}
                      name="HousingLevy"
                    />
                  }
                  label="Housing Levy"
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewEmployeeDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            Create Employee
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Employee Dialog */}
      <Dialog
        open={viewEmployeeDialog}
        onClose={() => setViewEmployeeDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Employee Details</Typography>
            <IconButton
              onClick={() => setViewEmployeeDialog(false)}
              size="small"
            >
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          {selectedEmployee && (
            <Grid container spacing={3}>
              {/* Personal Information */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Personal Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Full Name
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Gender
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Gender}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Date of Birth
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.DOB}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            ID Number
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.IDNo}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Email
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Email}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Phone
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Phone}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Marital Status
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.MaritalStatus}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Education */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Education
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          College/University
                        </Typography>
                        <Typography variant="body1">
                          {selectedEmployee.College}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Course
                        </Typography>
                        <Typography variant="body1">
                          {selectedEmployee.Course}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Next of Kin */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Next of Kin Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Name
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.NextOfKinName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Relationship
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.NextOfKinRelationship}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Contact
                        </Typography>
                        <Typography variant="body1">
                          {selectedEmployee.NextOfKinContact}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Employment Details */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Employment Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Employee No
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.EmployeeNo}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Designation
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Designation}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Date of Employment
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.DOE}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            NHIF Number
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.NHIFNumber}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            NSSF Number
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.NSSFNumber}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Status
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: selectedEmployee.Status
                                ? "success.main"
                                : "error.main",
                              fontWeight: "medium",
                            }}
                          >
                            {selectedEmployee.Status ? "Active" : "Inactive"}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Salary Details */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Salary & Deductions
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Gross Pay
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Gross?.toLocaleString("en-KE", {
                              style: "currency",
                              currency: "KES",
                            })}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            HELB
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.HELB?.toLocaleString("en-KE", {
                              style: "currency",
                              currency: "KES",
                            })}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Pension
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Pension?.toLocaleString("en-KE", {
                              style: "currency",
                              currency: "KES",
                            })}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Loan
                          </Typography>
                          <Typography variant="body1">
                            {selectedEmployee.Loan?.toLocaleString("en-KE", {
                              style: "currency",
                              currency: "KES",
                            })}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Statutory Deductions
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              variant="body2"
                              color={
                                selectedEmployee.PAYE
                                  ? "success.main"
                                  : "text.secondary"
                              }
                            >
                              PAYE
                            </Typography>
                            <Typography
                              variant="body2"
                              color={
                                selectedEmployee.NHIF
                                  ? "success.main"
                                  : "text.secondary"
                              }
                            >
                              NHIF
                            </Typography>
                            <Typography
                              variant="body2"
                              color={
                                selectedEmployee.NSSF
                                  ? "success.main"
                                  : "text.secondary"
                              }
                            >
                              NSSF
                            </Typography>
                            <Typography
                              variant="body2"
                              color={
                                selectedEmployee.HousingLevy
                                  ? "success.main"
                                  : "text.secondary"
                              }
                            >
                              Housing Levy
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewEmployeeDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Employee Dialog */}
      <Dialog
        open={editEmployeeDialog}
        onClose={() => setEditEmployeeDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Edit Employee</Typography>
            <IconButton
              onClick={() => setEditEmployeeDialog(false)}
              size="small"
            >
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          {editFormData && (
            <Grid container spacing={3}>
              {/* Personal Information */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Personal Information
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Full Name"
                  name="Name"
                  value={editFormData.Name}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Gender</InputLabel>
                  <MuiSelect
                    label="Gender"
                    name="Gender"
                    value={editFormData.Gender}
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="ID Number"
                  name="IDNo"
                  value={editFormData.IDNo}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                  value={editFormData.DOB}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Marital Status</InputLabel>
                  <MuiSelect
                    label="Marital Status"
                    name="MaritalStatus"
                    value={editFormData.MaritalStatus}
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="Single">Single</MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                    <MenuItem value="Divorced">Divorced</MenuItem>
                    <MenuItem value="Widowed">Widowed</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Grid>

              {/* Education */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Education
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="College/University"
                  name="College"
                  value={editFormData.College}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Course"
                  name="Course"
                  value={editFormData.Course}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>

              {/* Next of Kin */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Next of Kin Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Next of Kin Name"
                  name="NextOfKinName"
                  value={editFormData.NextOfKinName}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Relationship</InputLabel>
                  <MuiSelect
                    label="Relationship"
                    name="NextOfKinRelationship"
                    value={editFormData.NextOfKinRelationship}
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="Spouse">Spouse</MenuItem>
                    <MenuItem value="Parent">Parent</MenuItem>
                    <MenuItem value="Child">Child</MenuItem>
                    <MenuItem value="Sibling">Sibling</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Next of Kin Contact"
                  name="NextOfKinContact"
                  value={editFormData.NextOfKinContact}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>

              {/* Employment Details */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Employment Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Employee No"
                  name="EmployeeNo"
                  value={editFormData.EmployeeNo}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Designation"
                  name="Designation"
                  value={editFormData.Designation}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="NHIF Number"
                  name="NHIFNumber"
                  value={editFormData.NHIFNumber}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="NSSF Number"
                  name="NSSFNumber"
                  value={editFormData.NSSFNumber}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>

              {/* Contact Information */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  name="Email"
                  type="email"
                  value={editFormData.Email}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Phone"
                  name="Phone"
                  value={editFormData.Phone}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>

              {/* Salary Details */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Salary & Deductions
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Gross Pay"
                  name="Gross"
                  type="number"
                  value={editFormData.Gross}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: Number(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="HELB"
                  name="HELB"
                  type="number"
                  value={editFormData.HELB}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: Number(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Pension"
                  name="Pension"
                  type="number"
                  value={editFormData.Pension}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: Number(e.target.value),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Loan"
                  name="Loan"
                  type="number"
                  value={editFormData.Loan}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      [e.target.name]: Number(e.target.value),
                    })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editFormData.PAYE}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            PAYE: e.target.checked,
                          })
                        }
                        name="PAYE"
                      />
                    }
                    label="PAYE"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editFormData.NHIF}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            NHIF: e.target.checked,
                          })
                        }
                        name="NHIF"
                      />
                    }
                    label="NHIF"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editFormData.NSSF}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            NSSF: e.target.checked,
                          })
                        }
                        name="NSSF"
                      />
                    }
                    label="NSSF"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editFormData.HousingLevy}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            HousingLevy: e.target.checked,
                          })
                        }
                        name="HousingLevy"
                      />
                    }
                    label="Housing Levy"
                  />
                </Stack>
              </Grid>

              {/* Status */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Employee Status</InputLabel>
                  <MuiSelect
                    label="Employee Status"
                    name="Status"
                    value={editFormData.Status}
                    onChange={(e) =>
                      setEditFormData({
                        ...editFormData,
                        Status: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditEmployeeDialog(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "Do you want to save these changes?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, save changes",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleEdit();
                }
              });
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {loading && <WaveLoading />}
    </Box>
  );
}

const EmpItem = (props) => {
  const [showing, setShowing] = useState(false);
  const [formData, setFormData] = useState({
    Name: props.item.Name,
    Gender: props.item.Gender,
    Email: props.item.Email,
    Phone: props.item.Phone,
    EmployeeNo: props.item.EmployeeNo,
    Designation: props.item.Designation,
    Gross: props.item.Gross,
    DOE: props.item.DOE,
    HELB: props.item.HELB,
    Pension: props.item.Pension,
    Loan: props.item.Loan,
    Status: props.item.Status,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Show loading state
      Swal.fire({
        title: "Updating...",
        text: "Please wait while we update the employee details",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await fetch(`/api/employees/update/${props.item.ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.success) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Employee updated successfully",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          setShowing(false);
          // Refresh employee list
          window.location.reload();
        });
      } else {
        throw new Error(data.error || "Failed to update employee");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="div1auto">
          <div
            style={{
              backgroundColor: props.item.Status ? "antiquewhite" : "red",
            }}
            className="no"
          >
            {props.item.EmployeeNo}
          </div>
          <div>
            <h3>{props.item.Name}</h3>
            <p>
              {props.item.Phone} - {props.item.Email}
            </p>
          </div>
          {props.item.Gender == "Female" ? (
            <BsGenderFemale className="gender" size={24} color={"#b31dc7"} />
          ) : (
            <BsGenderMale className="gender" size={24} color="blue" />
          )}
        </div>
        <hr />
        <div className="designation">{props.item.Designation}</div>

        <div>
          <p>
            <b>Gross Pay: </b>
            {props.item.Gross.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>
            <b>Statutory Deductions: </b>{" "}
            {calculateStatutory(props.item).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>
            <b>Other Deductions: </b>{" "}
            {calculateDeductions(props.item).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>
            <b>Net Pay: </b>
            {(
              props.item.Gross -
              calculateStatutory(props.item) -
              calculateDeductions(props.item)
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <Edit
          onClick={() => {
            setShowing(true);
          }}
          className="edit"
          icon={faUserEdit}
        />
        <Delete className="delete" icon={faTrash} />
      </div>
      <Dialog
        open={showing}
        onClose={() => setShowing(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Edit Employee</Typography>
            <IconButton onClick={() => setShowing(false)} size="small">
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Employee Details
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="Name"
                value={props.item.Name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Employee No"
                name="EmployeeNo"
                value={props.item.EmployeeNo}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <MuiSelect
                  label="Gender"
                  name="Gender"
                  value={props.item.Gender}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </MuiSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Designation"
                name="Designation"
                value={props.item.Designation}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="Email"
                type="email"
                value={props.item.Email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="Phone"
                value={props.item.Phone}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Gross Pay"
                name="Gross"
                type="number"
                value={props.item.Gross}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of Employment"
                name="DOE"
                type="date"
                value={props.item.DOE}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Deductions
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="HELB"
                name="HELB"
                type="number"
                value={props.item.HELB}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Pension"
                name="Pension"
                type="number"
                value={props.item.Pension}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Loan(s)"
                name="Loan"
                type="number"
                value={props.item.Loan}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <MuiSelect
                  label="Status"
                  name="Status"
                  value={props.item.Status.toString()}
                  onChange={handleInputChange}
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </MuiSelect>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowing(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              // Add confirmation before submitting
              Swal.fire({
                title: "Are you sure?",
                text: "Do you want to save these changes?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, save changes",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleSubmit();
                }
              });
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function calculateStatutory(item) {
  let d = 0;

  if (item.PAYE) {
    d += item.Gross * 0.16;
  }
  if (item.NHIF) {
    d += 1500;
  }
  if (item.NSSF) {
    d += 2160;
  }
  if (item.HousingLevy) {
    d += item.Gross * 0.0275;
  }
  return d;
}

function calculateDeductions(item) {
  return item.HELB + item.Loan + item.Pension;
}

function getLastDateOfPreviousMonth(m, y) {
  // Get the current date
  let currentDate = new Date(`${y}-${m}-01`);

  // Set the date to the first day of the current month
  currentDate.setDate(1);

  // Subtract one day to get the last day of the previous month
  currentDate.setDate(currentDate.getDate() - 1);

  // Format the date to yyyy-mm-dd
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  let day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
