import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Grid,
  TextField,
  Button,
  Divider,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import {
  Person,
  Email,
  Badge,
  AccessTime,
  ExitToApp,
  VpnKey,
} from "@mui/icons-material";

export default function Settings() {
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState("");
  const [error, setError] = useState("");
  const psd = useRef();
  const npsd = useRef();
  const cpsd = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          navigate("/login");
        } else {
          setCurrentUser(decoded);
        }
      } catch (error) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, []);

  function changePassword() {
    if (
      cpsd.current.value === "" ||
      npsd.current.value === "" ||
      psd.current.value === ""
    ) {
      return setError("All fields are required!");
    }
    if (cpsd.current.value !== npsd.current.value) {
      return setError("Passwords do not match!");
    }
    let body = {
      Password: psd.current.value,
      NewPassword: npsd.current.value,
    };
    fetch(`/api/auth/${currentUser.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            localStorage.removeItem("gfgfgggn");
            navigate("/login");
          }, 1000);
        } else setError(data.error);
      })
      .catch((e) => {});
  }

  function convertTime(dt) {
    const date = new Date(dt * 1000);
    return date.toString();
  }

  return (
    <Box sx={{ p: 3, maxWidth: 1200, mx: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      <Grid container spacing={3}>
        {/* User Details Card */}
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
            <Stack direction="row" alignItems="center" spacing={1} mb={2}>
              <Person color="primary" />
              <Typography variant="h6">User Details</Typography>
            </Stack>
            <Divider sx={{ mb: 3 }} />

            {currentUser !== "" && (
              <Grid container spacing={3}>
                {Object.keys(currentUser).map((item, i) => {
                  if (item !== "iat" && item !== "exp") {
                    return (
                      <Grid item xs={12} sm={6} key={item}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              gutterBottom
                            >
                              {item}
                            </Typography>
                            <Typography variant="body1">
                              {currentUser[item]}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  } else if (item === "iat") {
                    return (
                      <Grid item xs={12} sm={6} key={item}>
                        <Card variant="outlined">
                          <CardContent>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <AccessTime color="primary" fontSize="small" />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                gutterBottom
                              >
                                Login Time
                              </Typography>
                            </Stack>
                            <Typography variant="body1">
                              {convertTime(currentUser[item]).split("GMT")[0]}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  } else if (item === "exp") {
                    return (
                      <Grid item xs={12} sm={6} key={item}>
                        <Card variant="outlined">
                          <CardContent>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <ExitToApp color="primary" fontSize="small" />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                gutterBottom
                              >
                                Logout Time
                              </Typography>
                            </Stack>
                            <Typography variant="body1">
                              {convertTime(currentUser[item]).split("GMT")[0]}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            )}
          </Paper>
        </Grid>

        {/* Change Password Card */}
        <Grid item xs={12} md={5}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Stack direction="row" alignItems="center" spacing={1} mb={2}>
              <VpnKey color="primary" />
              <Typography variant="h6">Change Password</Typography>
            </Stack>
            <Divider sx={{ mb: 3 }} />

            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Old Password"
                  type="password"
                  inputRef={psd}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="New Password"
                  type="password"
                  inputRef={npsd}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  inputRef={cpsd}
                  variant="outlined"
                />

                {error && (
                  <Typography
                    color={error.includes("Success") ? "success.main" : "error"}
                    textAlign="center"
                  >
                    {error}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  size="large"
                  onClick={changePassword}
                  sx={{ mt: 2 }}
                >
                  Update Password
                </Button>
              </Stack>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
