import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  useTheme,
  Stack,
  Divider,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  MoneyOff,
  People,
  AccountBalance,
  Payments,
  LocalAtm,
  Receipt,
  CurrencyExchange,
  AccountBalanceWallet,
} from "@mui/icons-material";
import CustomPieChart from "../Stats/CustomPieChart";
import pr from "../../assets/images/payroll.png";
import bn from "../../assets/images/bonus.png";
import emp from "../../assets/images/employees.png";
import net from "../../assets/images/net.png";
import dd from "../../assets/images/deduction.png";
import st from "../../assets/images/statutory.png";
import pay from "../../assets/images/pay.png";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getMonths, getYears } from "../../assets/data/data";

export default function PayrollDashboard() {
  const [stats, setStats] = useState({
    employees: 0,
    payroll: 0,
    net: 0,
    bonus: 0,
    deductions: 0,
    statutory: 0,
    paid: 0,
  });
  const theme = useTheme();
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    // Fetch payslip data
    fetch(`/api/payslip/bymonth/${month}/${year}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        let d = { bonus: 0, paid: 0 };
        data.map((item) => {
          d.bonus += item.Bonus;
          d.paid += item.Gross;
        });
        setStats((prev) => ({ ...prev, ...d }));
      })
      .catch((e) => {});

    // Fetch employee data
    fetch(`/api/employees/${month}/${year}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        let d = {
          employees: data.length,
          payroll: 0,
          net: 0,
          deductions: 0,
          statutory: 0,
        };

        data.forEach((item) => {
          d.payroll += item.Gross;
          d.statutory += calculateStatutory(item);
          d.deductions += calculateDeductions(item);
          d.net +=
            item.Gross - calculateStatutory(item) - calculateDeductions(item);
        });

        setStats((prev) => ({ ...prev, ...d }));
      })
      .catch((e) => {});
  }, [month, year]);

  const StatCard = ({ title, value, icon, color, image }) => (
    <Card
      elevation={1}
      sx={{
        height: "100%",
        background: `linear-gradient(135deg, ${color}15 0%, ${color}05 100%)`,
        border: `1px solid ${color}20`,
      }}
    >
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: `${color}15`,
            }}
          >
            {image ? (
              <img src={image} alt={title} style={{ width: 24, height: 24 }} />
            ) : (
              icon
            )}
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {typeof value === "number"
                ? value.toLocaleString("en-US", {
                    style: title === "Total Employees" ? "decimal" : "currency",
                    currency: "KES",
                    minimumFractionDigits: 2,
                  })
                : value}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );

  // Add this function to format currency in the tooltip
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-KE", {
      style: "currency",
      currency: "KES",
      minimumFractionDigits: 2,
    }).format(value);
  };

  // Add this custom tooltip component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper
          elevation={3}
          sx={{
            p: 2,
            backgroundColor: "rgba(255, 255, 255, 0.95)",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ color: entry.color, fontWeight: "medium" }}
            >
              {`${entry.name}: ${formatCurrency(entry.value)}`}
            </Typography>
          ))}
        </Paper>
      );
    }
    return null;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Payroll Overview</Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            select
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            sx={{ width: 150 }}
            size="small"
          >
            {getMonths.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.month}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            sx={{ width: 100 }}
            size="small"
          >
            {getYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Employees"
            value={stats.employees}
            image={emp}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Payroll Cost"
            value={stats.payroll}
            image={pr}
            color={theme.palette.secondary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Net Salary"
            value={stats.net}
            image={net}
            color={theme.palette.success.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Bonus"
            value={stats.bonus}
            image={bn}
            color={theme.palette.info.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Deductions"
            value={stats.deductions}
            image={dd}
            color={theme.palette.error.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Statutory"
            value={stats.statutory}
            image={st}
            color={theme.palette.warning.main}
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              height: "450px",
              background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Payroll Breakdown
            </Typography>
            <Divider sx={{ my: 2 }} />
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={[
                  {
                    name: "Payroll Metrics",
                    "Gross Cost": stats.payroll,
                    "Net Payroll": stats.net,
                    Deductions: stats.deductions,
                    Statutory: stats.statutory,
                  },
                ]}
                margin={{
                  top: 20,
                  right: 30,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("en-KE", {
                      notation: "compact",
                      compactDisplay: "short",
                      currency: "KES",
                    }).format(value)
                  }
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar
                  dataKey="Gross Cost"
                  fill={theme.palette.secondary.main}
                  radius={[4, 4, 0, 0]}
                />
                <Bar
                  dataKey="Net Payroll"
                  fill={theme.palette.success.main}
                  radius={[4, 4, 0, 0]}
                />
                <Bar
                  dataKey="Deductions"
                  fill={theme.palette.error.main}
                  radius={[4, 4, 0, 0]}
                />
                <Bar
                  dataKey="Statutory"
                  fill={theme.palette.warning.main}
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              height: "100%",
              background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Payment Status
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box>
              <CustomPieChart
                color={[theme.palette.success.main, theme.palette.warning.main]}
                data={[
                  { name: "Paid", value: stats.paid },
                  { name: "Not Paid", value: stats.payroll - stats.paid },
                ]}
                aspect={1}
                colors={[
                  theme.palette.success.main,
                  theme.palette.warning.main,
                ]}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" color="textSecondary">
                Next Payroll Date:{" "}
                {new Date().toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

// Helper functions
function calculateStatutory(item) {
  let d = 0;
  if (item.PAYE) d += item.Gross * 0.16;
  if (item.NHIF) d += 1500;
  if (item.NSSF) d += 2160;
  if (item.HousingLevy) d += item.Gross * 0.0275;
  return d;
}

function calculateDeductions(item) {
  return item.HELB + item.Loan + item.Pension;
}
