import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton as MuiIconButton,
  Divider,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Visibility, Search, Close, Payment } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getMonths, getYears } from "../../assets/data/data";
import WaveLoading from "../Util/WaveLoading";
import Swal from "sweetalert2";

export default function PayrollList() {
  const [data, setData] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [payDialog, setPayDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [other, setOther] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, [month, year]);

  const fetchEmployees = async () => {
    try {
      const response = await fetch(`/api/employees/${month}/${year}`);
      const fetchedData = await response.json();
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching employees:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error fetching employee data!",
      });
    }
  };

  const formatCurrency = (amount) => {
    const value = parseFloat(amount) || 0;
    return new Intl.NumberFormat("en-KE", {
      style: "currency",
      currency: "KES",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handlePayrollClick = (employee) => {
    if (employee.Status) {
      navigate(`/payroll/payslip/${employee.ID}/${month}/${year}`);
    } else {
     
    }
  };

  const calculatePAYE = (gross) => {
    if (gross <= 24000) return 0;

    let tax = 0;
    let taxableAmount = gross;

    // First 24,000 @ 10%
    tax += 24000 * 0.1;
    taxableAmount -= 24000;

    // Next 8,333 @ 25%
    if (taxableAmount > 0) {
      const band2 = Math.min(taxableAmount, 8333);
      tax += band2 * 0.25;
      taxableAmount -= band2;
    }

    // Next 467,667 @ 30%
    if (taxableAmount > 0) {
      const band3 = Math.min(taxableAmount, 467667);
      tax += band3 * 0.3;
      taxableAmount -= band3;
    }

    // Next 300,000 @ 32.5%
    if (taxableAmount > 0) {
      const band4 = Math.min(taxableAmount, 300000);
      tax += band4 * 0.325;
      taxableAmount -= band4;
    }

    // Remaining amount @ 35%
    if (taxableAmount > 0) {
      tax += taxableAmount * 0.35;
    }

    // Personal Relief
    tax = Math.max(tax - 2400, 0);

    return Math.round(tax); // Round to nearest whole number
  };

  const calculateNetSalary = (
    gross,
    paye,
    nhif,
    nssf,
    housingLevy,
    helb,
    loan,
    pension,
    bonus,
    other
  ) => {
    return (
      gross -
      paye -
      nhif -
      nssf -
      housingLevy -
      helb -
      loan -
      pension +
      bonus -
      other
    );
  };

  const calculateStatutory = (item) => {
    let d = 0;
    const gross = parseFloat(item.Gross) || 0;

    if (gross === 0) return 0;

    if (item.PAYE) {
      d += calculatePAYE(gross);
    }

    if (item.NHIF) {
      const nhifAmount = Math.max(gross * 0.0275, 300);
      d += nhifAmount;
    }

    if (item.NSSF) {
      d += 4320;
    }

    if (item.HousingLevy) {
      d += gross * 0.015;
    }

    return d;
  };

  const calculateDeductions = (item) => {
    const gross = parseFloat(item.Gross) || 0;

    if (gross === 0) return 0;

    return (
      (parseFloat(item.HELB) || 0) +
      (parseFloat(item.Loan) || 0) +
      (parseFloat(item.Pension) || 0)
    );
  };

  const createPayroll = async () => {
    if (!selectedEmployee) return;

    const gross = parseFloat(selectedEmployee.Gross) || 0;
    const paye =
      gross === 0 ? 0 : selectedEmployee.PAYE ? calculatePAYE(gross) : 0;
    const nhif =
      gross === 0
        ? 0
        : selectedEmployee.NHIF
        ? Math.max(gross * 0.0275, 300)
        : 0;
    const nssf = gross === 0 ? 0 : selectedEmployee.NSSF ? 4320 : 0;
    const housingLevy =
      gross === 0
        ? 0
        : selectedEmployee.HousingLevy
        ? Math.round(gross * 0.015)
        : 0;
    const helb = gross === 0 ? 0 : parseFloat(selectedEmployee.HELB) || 0;
    const loan = gross === 0 ? 0 : parseFloat(selectedEmployee.Loan) || 0;
    const pension = gross === 0 ? 0 : parseFloat(selectedEmployee.Pension) || 0;

    const netSalary = calculateNetSalary(
      gross,
      paye,
      nhif,
      nssf,
      housingLevy,
      helb,
      loan,
      pension,
      parseFloat(bonus) || 0,
      parseFloat(other) || 0
    );

    const body = {
      EmployeeID: selectedEmployee.ID,
      Gross: gross,
      PAYE: paye,
      NHIF: nhif,
      NSSF: nssf,
      HousingLevy: housingLevy,
      Pension: pension,
      HELB: helb,
      Loan: loan,
      Bonus: parseFloat(bonus) || 0,
      Other: parseFloat(other) || 0,
      NetSalary: netSalary,
      Date: getLastDateOfPreviousMonth(month, year),
    };

    setLoading(true);
    try {
      const response = await fetch("/api/payslip/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (data.success) {
        setPayDialog(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Payslip generated successfully",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          navigate(`/payroll/payslip/${selectedEmployee.ID}/${month}/${year}`);
        });
      } else {
        throw new Error(data.error || "Failed to create payslip");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "Name",
      headerName: "Employee",
      flex: 1,
    },
    {
      field: "EmployeeNo",
      headerName: "Employee No",
    },
    {
      field: "Gross",
      headerName: "Gross Pay",
      type: "number",
      width: 130,
      renderCell: (params) => formatCurrency(params?.row?.Gross),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "statutory",
      headerName: "Statutory",
      type: "number",
      width: 130,
      renderCell: (params) => {
        const statutory = calculateStatutory(params.row);
        return formatCurrency(statutory);
      },
      align: "right",
      headerAlign: "right",
    },
    {
      field: "deductions",
      headerName: "Other Deductions",
      type: "number",
      renderCell: (params) =>
        formatCurrency(params.row.HELB + params.row.Loan + params.row.Pension),
      align: "right",
      headerAlign: "right",
    },
    {
      field: "netPay",
      headerName: "Net Pay",
      type: "number",
      width: 130,
      renderCell: (params) => {
        const gross = parseFloat(params.row.Gross) || 0;
        const statutory = calculateStatutory(params.row);
        const deductions = calculateDeductions(params.row);
        return formatCurrency(gross - statutory - deductions);
      },
      align: "right",
      headerAlign: "right",
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          color={params.value ? "success" : "warning"}
          sx={{ minWidth: 85 }}
        >
          {params.value ? "Paid" : "Not Paid"}
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
      <Box sx={{display: "flex", justifyContent: "center"}}>
      <MuiIconButton
          onClick={(e) => {
            e.stopPropagation();
             setSelectedEmployee(params.row);
             setPayDialog(true);
          }}
        >
          <Payment />
        </MuiIconButton>
        <MuiIconButton
          onClick={(e) => {
            e.stopPropagation();      
            navigate(`/payroll/payslip/${params.row.ID}/${month}/${year}`);
          }}
        >
          <Visibility />
        </MuiIconButton>
        </Box>
      ),
    },
  ];

  const PaymentDetails = () => {
    if (!selectedEmployee) return null;

    const gross = parseFloat(selectedEmployee.Gross) || 0;
    const paye = selectedEmployee.PAYE ? calculatePAYE(gross) : 0;
    const nhif = selectedEmployee.NHIF ? Math.max(gross * 0.0275, 300) : 0;
    const nssf = selectedEmployee.NSSF ? 4320 : 0;
    const housingLevy = selectedEmployee.HousingLevy
      ? Math.round(gross * 0.015)
      : 0;
    const helb = parseFloat(selectedEmployee.HELB) || 0;
    const loan = parseFloat(selectedEmployee.Loan) || 0;
    const pension = parseFloat(selectedEmployee.Pension) || 0;
    const bonusAmount = parseFloat(bonus) || 0;
    const otherAmount = parseFloat(other) || 0;

    const netSalary = calculateNetSalary(
      gross,
      paye,
      nhif,
      nssf,
      housingLevy,
      helb,
      loan,
      pension,
      bonusAmount,
      otherAmount
    );

    return (
      <Stack spacing={2}>
        <Typography variant="subtitle1" color="textSecondary">
          Deduction Breakdown
        </Typography>
        <Box>
          <Typography variant="body2">PAYE: {formatCurrency(paye)}</Typography>
          <Typography variant="body2">NHIF: {formatCurrency(nhif)}</Typography>
          <Typography variant="body2">NSSF: {formatCurrency(nssf)}</Typography>
          <Typography variant="body2">
            Housing Levy: {formatCurrency(housingLevy)}
          </Typography>
          <Typography variant="body2">HELB: {formatCurrency(helb)}</Typography>
          <Typography variant="body2">Loan: {formatCurrency(loan)}</Typography>
          <Typography variant="body2">
            Pension: {formatCurrency(pension)}
          </Typography>
        </Box>
        <Divider />
        <Typography variant="h6">
          Net Salary: {formatCurrency(netSalary)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Payroll List</Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/payroll/employees")}
        >
          Manage Employees
        </Button>
      </Stack>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Stack direction="row" spacing={2} mb={3}>
          <TextField
            select
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            sx={{ width: 200 }}
            size="small"
          >
            {getMonths.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.month}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            sx={{ width: 120 }}
            size="small"
          >
            {getYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight
          getRowId={(row) => row.ID}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
         // onRowClick={(params) => handlePayrollClick(params.row)}
        />
      </Paper>

      {/* Payment Dialog */}
      <Dialog
        open={payDialog}
        onClose={() => setPayDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Process Payment - {selectedEmployee?.Name}
            </Typography>
            <MuiIconButton onClick={() => setPayDialog(false)} size="small">
              <Close />
            </MuiIconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={3}>
            <Box>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Employee No.
              </Typography>
              <Typography variant="body1">
                {selectedEmployee?.EmployeeNo}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Gross Pay
              </Typography>
              <Typography variant="body1">
                {formatCurrency(selectedEmployee?.Gross || 0)}
              </Typography>
            </Box>

            <TextField
              label="Bonus Amount"
              type="number"
              value={bonus}
              onChange={(e) => setBonus(Number(e.target.value))}
              fullWidth
            />

            <TextField
              label="Other Deductions"
              type="number"
              value={other}
              onChange={(e) => setOther(Number(e.target.value))}
              fullWidth
            />

            <PaymentDetails />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPayDialog(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={createPayroll}
            disabled={loading}
          >
            Generate Payslip
          </Button>
        </DialogActions>
      </Dialog>

      {loading && <WaveLoading />}
    </Box>
  );
}

function getLastDateOfPreviousMonth(m, y) {
  let currentDate = new Date(`${y}-${m}-01`);
  currentDate.setDate(1);
  currentDate.setDate(currentDate.getDate() - 1);
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0");
  let day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
