import {
  faAddressBook,
  faArrowLeftLong,
  faAt,
  faDownload,
  faEnvelope,
  faPhone,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Watermark } from "@hirohe/react-watermark";
import "../../Styles/payroll.scss";
import logo from "../../assets/images/logo.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import { useNavigate } from "react-router-dom";
import {
  ArrowBack,
  Download,
  Email,
  Mail,
  Person,
  Phone,
} from "@mui/icons-material";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Grid,
  Button,
  IconButton,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import Swal from "sweetalert2";

// Move formatCurrency outside components
const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-KE", {
    style: "currency",
    currency: "KES",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(amount) || 0);
};

const PayslipRow = ({ label, value, bold = false, isTotal = false }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      py: 1,
      px: 2,
      bgcolor: isTotal ? "primary.light" : "transparent",
      borderBottom: "1px solid",
      borderColor: "divider",
      "& .MuiTypography-root": {
        fontWeight: bold || isTotal ? "bold" : "normal",
      },
    }}
  >
    <Typography>{label}</Typography>
    <Typography>{value}</Typography>
  </Box>
);

export default function Payslip() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [payData, setPayData] = useState(null);
  const [payslips, setPayslips] = useState(null);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(null);
  const id = window.location.pathname.split("/")[3];
  const month = window.location.pathname.split("/")[4];
  const year = window.location.pathname.split("/")[5];
  const [monthname, setMonthname] = useState(null);

  useEffect(() => {
    fetch(`/api/employees/employeeid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.length > 0) setData(data[0]);
      })
      .catch((e) => {});
    fetch(`/api/payslip/employeeid/${id}/${month}/${year}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.length > 0) {
          setPayData(data[0]);
          const date = new Date(data[0].Date);
          // Get month name
          const monthName = date.toLocaleString("default", { month: "long" });
          setMonthname(monthName);
        }
      })
      .catch((e) => {});
  }, [id, month, year]);

  useEffect(() => {
    fetch(`/api/payslip/paginated/${id}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setPayslips(data);
      })
      .catch((e) => {});
  }, [id, offset]);

  const downloadPdfDocument = () => {
    const invoice = document.getElementById("invoice");
    html2canvas(invoice, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: [612, 792],
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      const downloadFileName = `${data?.Name}_Payslip_${monthname}_${year}`;
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  const sendPdfDocument = () => {
    const invoice = document.getElementById("invoice");
    html2canvas(invoice, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: [612, 792],
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      const downloadFileName = `${data?.Name}_Payslip_${monthname}_${year}`;
      const pdfOutput = pdf.output("blob");

      // Show loading state
      Swal.fire({
        title: "Sending...",
        text: "Please wait while we send the payslip",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Send PDF
      sendData(pdfOutput, downloadFileName);
    });
  };

  const sendData = (pdf, downloadFileName) => {
    const formData = new FormData();
    formData.append("Payslip", pdf, `${downloadFileName}.pdf`);
    formData.append("Email", data?.Email);
    formData.append("Name", data?.Name);
    formData.append("Month", monthname ? monthname : month);
    formData.append("Year", year);

    fetch(`/api/payslip/sendemail/${data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to send email");
      })
      .then((data) => {
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Payslip sent successfully",
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          throw new Error(data.error || "Failed to send email");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack direction="row" spacing={2} alignItems="center" mb={3}>
        <IconButton onClick={() => navigate("/payroll")}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5">
          Payslip for {monthname} {year}
        </Typography>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              maxWidth: 800,
              mx: "auto",
              "& .section-title": {
                bgcolor: "primary.main",
                color: "primary.contrastText",
                py: 1,
                px: 2,
                mb: 1,
              },
            }}
            id="invoice"
          >
            {/* Header */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={3}
            >
              <img src={logo} alt="Logo" style={{ height: 80 }} />
              <Box textAlign="right">
                <Typography variant="h3" color="primary" gutterBottom>
                  PAYSLIP
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {monthname} {year}
                </Typography>
              </Box>
            </Stack>

            {/* Employee Info */}
            <Box mb={3}>
              <Typography
                variant="title"
                sx={{
                  width: "100%",
                  display: "block",
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                  py: 1,
                  px: 2,
                  mb: 1,
                }}
              >
                Employee Details
              </Typography>
              <Box sx={{ px: 2, py: 1 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Name: {data?.Name}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Phone: {data?.Phone}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Email: {data?.Email}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Employee No: {data?.EmployeeNo}
                </Typography>
              </Box>
            </Box>

            {/* Payslip Details */}
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="title"
                sx={{
                  width: "100%",
                  display: "block",
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                  py: 1,
                  px: 2,
                  mb: 1,
                }}
              >
                Earnings & Deductions
              </Typography>

              {/* Basic Pay */}
              <PayslipRow
                label="BASIC PAY"
                value={formatCurrency(payData?.Gross || 0)}
                bold
              />

              {/* Deductions Section */}
              <Box sx={{ bgcolor: "grey.50" }}>
                <PayslipRow
                  label="NSSF"
                  value={formatCurrency(payData?.NSSF || 0)}
                />
                <PayslipRow
                  label="NHIF"
                  value={formatCurrency(payData?.NHIF || 0)}
                />
                <PayslipRow
                  label="Housing Levy"
                  value={formatCurrency(payData?.HousingLevy || 0)}
                />
              </Box>

              {/* Taxable Pay */}
              <PayslipRow
                label="TAXABLE PAY"
                value={formatCurrency(
                  (payData?.Gross || 0) - (payData?.NSSF || 0)
                )}
                bold
              />

              {/* Tax Calculation */}
              <Box sx={{ bgcolor: "grey.50" }}>
                <PayslipRow
                  label="INCOME TAX"
                  value={formatCurrency(payData?.PAYE || 0)}
                />
                <PayslipRow label="Personal Relief" value="-2,400.00" />
                <PayslipRow
                  label="P.A.Y.E"
                  value={formatCurrency(
                    Math.max((payData?.PAYE || 0) - 2400, 0)
                  )}
                />
              </Box>

              {/* Final Calculations */}
              <PayslipRow
                label="PAY AFTER TAX"
                value={formatCurrency(
                  (payData?.Gross || 0) -
                    (payData?.NSSF || 0) -
                    Math.max((payData?.PAYE || 0) - 2400, 0)
                )}
                bold
              />

              {/* Net Pay */}
              <PayslipRow
                label="NET PAY"
                value={formatCurrency(payData?.NetSalary || 0)}
                isTotal
              />
            </Box>

            {/* Footer */}
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Typography variant="body2" color="textSecondary">
                This is a computer generated payslip and does not require
                signature
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Right Panel - Keep the action buttons and previous payslips */}
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            {/* Action Buttons */}
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
              <Stack spacing={2}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  startIcon={<Email />}
                  onClick={sendPdfDocument}
                  sx={{ py: 1.5 }}
                >
                  {payData && payData.Payslip ? "Send Again" : "Send Payslip"}
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  startIcon={<Download />}
                  onClick={downloadPdfDocument}
                  sx={{ py: 1.5 }}
                >
                  Download Payslip
                </Button>
              </Stack>
            </Paper>

            {/* Previous Payslips */}
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom color="primary.main">
                Previous Payslips
              </Typography>
              <Stack spacing={2}>
                {payslips?.data.map((item, i) => (
                  <PayslipItem
                    key={i}
                    item={item}
                    index={i}
                    id={id}
                    year={year}
                    month={month}
                  />
                ))}
              </Stack>
              <Box mt={3}>
                <Pagination
                  totalPages={payslips ? payslips.total : 1}
                  currentPage={offset + 1}
                  handlePageChange={(v) => setOffset(v - 1)}
                />
              </Box>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

const PayslipItem = ({ item, year, month, id }) => {
  const [date, setDate] = useState("January, 2024");
  const dd = item.Date.split("T")[0].split("-");
  const navigate = useNavigate();

  useEffect(() => {
    const date = new Date(item.Date);
    const monthName = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    setDate(`${monthName}, ${year}`);
  }, []);

  return (
    <Card
      sx={{
        bgcolor:
          dd[0] == year && dd[1] == month
            ? "primary.light"
            : "background.paper",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: 3,
        },
      }}
      onClick={() => {
        navigate(`/payroll/payslip/${id}/${dd[1]}/${dd[0]}`);
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="medium">
              {date}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Paid: {new Date(item?.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
          <Typography variant="h6" color="primary.main" fontWeight="bold">
            {formatCurrency(item?.NetSalary || 0)}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
