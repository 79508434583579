import React, { useState, useEffect } from "react";
import {
  Box,
  Grid2,
  Card,
  Typography,
  IconButton,
  Divider,
  Stack,
} from "@mui/material";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import GaugeChart from "react-gauge-chart";
import CustomBarChart from "../Stats/CustomBarChart";
import { useNavigate } from "react-router-dom";

export default function Invoices(props) {
  const [numbers, setNumbers] = useState(null);
  const [sdata, setSData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState("");
  var jwt = require("jsonwebtoken");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/invoices/getadminstats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setSData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    fetch(`/api/invoices/numbers`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setNumbers(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    if (props.user) {
      setUser(props.user.Name);
      if (props.user.Role !== "Admin" && props.user.Role !== "Super Admin") {
        navigate("/");
      }
    } else {
      navigate("/login");
    }
  }, [navigate, props.user]);

  function withCommas(x) {
    if (x === null) return 0;
    return parseFloat(x).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <Box sx={{ p: 0 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Dashboard</Typography>
        <IconButton onClick={() => setRefresh(!refresh)}>
          <FiRefreshCcw />
        </IconButton>
      </Box>

      <Grid2 container spacing={2} sx={{ mt: 2 }}>
        {/* Left Section */}
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Stack
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            spacing={2}
          >
            <Card
              sx={{ p: 2, boxShadow: "0px 8px 16px #60606040" }}
              display="flex"
              alignItems="center"
              gap={2}
              flexGrow={1}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <FaFileInvoiceDollar color="orange" className="ts" size={44} />
                <Box flexGrow={1}>
                  <Typography textAlign="right" variant="h5">
                    {sdata ? withCommas(sdata?.AllTasks) : 0}
                  </Typography>
                  <Typography textAlign="right">Total Invoices</Typography>
                </Box>
              </Box>
            </Card>
            <Card
              sx={{ p: 2, boxShadow: "0px 8px 16px #60606040" }}
              display="flex"
              alignItems="center"
              gap={2}
              flexGrow={1}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <FaFileInvoiceDollar color="orange" className="ts" size={44} />
                <Box flexGrow={1}>
                  <Typography textAlign="right" variant="h5">
                    {sdata ? withCommas(sdata?.Billed) : 0}
                  </Typography>
                  <Typography textAlign="right">Invoiced Amount</Typography>
                </Box>
              </Box>
            </Card>
            <Card
              sx={{ p: 2, boxShadow: "0px 8px 16px #60606040", flexGrow: 1 }}
            >
              <Typography variant="title" marginBottom={1}>
                Collection Rate
              </Typography>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                colors={["red", "orange", "green"]}
                arcWidth={0.3}
                percent={
                  sdata ? (sdata?.Collected / sdata?.Billed).toFixed(2) : 0
                }
                textColor="gray"
              />
            </Card>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card sx={{ p: 2, boxShadow: "0px 8px 16px #60606040" }}>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Monthly Performance</Typography>
              <CustomBarChart
                aspect={1.5}
                data={sdata ? sdata.Bar : null}
                color="orange"
              />
            </Box>
          </Card>
        </Grid2>
        {/* Right Section */}
        <Grid2 size={{ xs: 12, md: 3 }}>
          <Card
            sx={{
              p: 2,
              boxShadow: "0px 8px 16px #60606040",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: "10px",
                  height: "24px",
                  backgroundColor: "orange",
                }}
              />
              <Typography variant="h6">Revenue Collection</Typography>
            </Box>
            <Box flexGrow={1} sx={{ display: "grid", placeContent: "center" }}>
              <Box>
                <Typography variant="h5" textAlign="center" color="green">
                  KSh <br />
                  {sdata
                    ? (sdata?.Collected - 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </Typography>
                <Typography mt={2} textAlign="center">
                  Total Collected
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: "10px",
                  height: "16px",
                  backgroundColor: "orange",
                }}
              />
              <Typography>Invoiced: </Typography>
              <Typography flexGrow={1} textAlign="right" color="primary">
                KSh{" "}
                {sdata
                  ? (sdata?.Billed - 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2} sx={{ mt: 1 }}>
              <Box
                sx={{
                  width: "10px",
                  height: "16px",
                  backgroundColor: "green",
                }}
              />
              <Typography>Paid: </Typography>
              <Typography flexGrow={1} textAlign="right" color="primary">
                KSh{" "}
                {sdata
                  ? (sdata?.Collected - 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2} sx={{ mt: 1 }}>
              <Box
                sx={{ width: "10px", height: "16px", backgroundColor: "red" }}
              />
              <Typography>Pending: </Typography>
              <Typography flexGrow={1} textAlign="right" color="primary">
                KSh{" "}
                {sdata
                  ? (sdata?.Billed - sdata?.Collected).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : "0.00"}
              </Typography>
            </Box>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}
